import { makeStyles } from "@material-ui/core/styles";
import VillaOutlinedIcon from "@mui/icons-material/VillaOutlined";

const useStyles = makeStyles(() => ({
  styledRadiusDiv: {
    fontFamily: "Hind Siliguri",
    backgroundColor: "#0CB64A",
    border: "none",
    borderRadius: "6px",
    //padding: "0.1rem 0.3rem",
    padding: "0.1rem 0.5rem",
    margin: "0.2rem 0 0.2rem 0.3rem",
    width: "3rem",
    fontWeight: "bold",
    height: "1.5rem",
  },
  styledRadiusText: {
    fontFamily: "Hind Siliguri",
    //fontSize: "1rem",
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
}));

const RadiusDiv = ({ text, color, Icon }) => {
  const classes = useStyles();

  switch (text) {
    case "DEF":
      return (
        <button
          className={classes.styledRadiusDiv}
          style={{ backgroundColor: "#46c3ff" }}
        >
          <p className={classes.styledRadiusText}>{text}</p>
        </button>
      );
    case "FWD":
      return (
        <button
          className={classes.styledRadiusDiv}
          style={{ backgroundColor: "#FF964A" }}
        >
          <p className={classes.styledRadiusText}>{text}</p>
        </button>
      );
    case "GK":
      return (
        <button
          className={classes.styledRadiusDiv}
          style={{ backgroundColor: "#FD5F85" }}
        >
          <p className={classes.styledRadiusText}>{text}</p>
        </button>
      );
    case "MID":
      return (
        <button
          className={classes.styledRadiusDiv}
          style={{ backgroundColor: "#BCEB57" }}
        >
          <p className={classes.styledRadiusText}>{text}</p>
        </button>
      );
    default:
      return (
        <button
          className={classes.styledRadiusDiv}
          style={{ backgroundColor: color !== null ? color : "#116473" }}
        >
          <p className={classes.styledRadiusText}>
            {text === "" || text === null || text === undefined ? (
              <VillaOutlinedIcon style={{ height: "1.2rem", width: "auto" }} />
            ) : (
              text
            )}
          </p>
        </button>
      );
  }
};

export default RadiusDiv;
