import React, { Suspense, lazy, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import "./App.css";
import SpinnerNotLogged from "./components/Spinner/SpinnerNotLogged";
import { gapi } from "gapi-script";
import PlayerForExportCard from "./components/Cards/PlayerForExportCard";
// import { NavigationBarComponent } from "./components/Navbar/NavigationBarComponent";
// import { PathNavigator } from "./components/Navbar/PathNavigator";
// import { Breadcrum } from "./components/Navbar/Breadcrum";

// import { NewTrialUserRegister } from "./pages/NewLogin/components/NewTrialUserRegister";
// import { NewLogin } from "./pages/NewLogin/NewLogin";
// import { NewAgreement } from "./pages/NewLogin/components/NewAgreement";
// import { NewPayment } from "./pages/NewLogin/components/NewPayment";
// import Login from "./pages/Login/Login";

// import Login from "./pages/Login/Login";
//Components
//PAGES

const NavigationBarComponent = lazy(() =>
    import("./components/Navbar/NavigationBarComponent")
);
const PathNavigator = lazy(() => import("./components/Navbar/PathNavigator"));
const Breadcrum = lazy(() => import("./components/Navbar/Breadcrum"));

const FanState = lazy(() => import("./context/FanContext/FanState"));
const NewTrialUserRegister = lazy(() =>
    import("./pages/NewLogin/components/NewTrialUserRegister")
);
const NewLogin = lazy(() => import("./pages/NewLogin/NewLogin"));
const NewAgreement = lazy(() =>
    import("./pages/NewLogin/components/NewAgreement")
);
const NewPayment = lazy(() => import("./pages/NewLogin/components/NewPayment"));

const AdminLayout = lazy(() => import("./components/Admin/AdminLayout/AdminLayout"));
//PAGES
const AceptUser = lazy(() => import("./pages/ValidationUser/AceptUser"));
const AdminCategories = lazy(() => import("./pages/Admin/AdminCategories/AdminCategories"));
const AdminClub = lazy(() => import("./pages/Admin/AdminClub/AdminClub"));
const AdminCreate = lazy(() => import("./pages/Admin/AdminCreate/AdminCreate"));
const AdminEvaluations = lazy(() => import("./pages/Admin/AdminEvaluations/AdminEvaluations"));
const AdminTeams = lazy(() => import("./pages/Admin/AdminTeams/AdminTeams"));
const AdminUsers = lazy(() => import("./pages/Admin/AdminUsers/AdminUsers"));
const AuthLayoutRoute = lazy(() =>  import("./components/AuthLayoutRoute/AuthLayoutRoute"));
const Category = lazy(() => import("./pages/Category/Category"));
const Club = lazy(() => import("./pages/Club/Club"));
const CreateEvent = lazy(() => import("./pages/CreateEvent/CreateEvent"));
const CreateUser = lazy(() => import("./pages/Admin/AdminUsers/components/CreateUser"));
const CreatePlayerList = lazy(() => import("./pages/CreateTeam/CreatePlayerList"));
const CreateTeam = lazy(() => import("./pages/CreateTeam/CreateTeam"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Documentation = lazy(() => import("./pages/Documentation/Documentation"));
const Gps = lazy(() => import("./pages/Gps/Gps"));
const Login = lazy(() => import("./pages/Login/Login"));
const MakeEvaluation = lazy(() => import("./pages/MakeEvaluation/MakeEvaluation"));
const MakeEvaluationPlayer = lazy(() => import("./pages/MakeEvaluation/MakeEvaluationPlayer"));
//const Player = lazy(() => import( "./pages/Player/Player"));
const PlayerDocumentation = lazy(() => import("./pages/PlayerDocumentation/PlayerDocumentation"));
const PlayerProfile = lazy(() => import("./pages/Player/PlayerProfile"));
const PlayerSummary = lazy(() => import("./pages/PlayerSummary/PlayerSummary"));
const Recover = lazy(() => import("./pages/Recover/Recover"));
const Register = lazy(() => import("./pages/Register/Register"));
const RejectUser = lazy(() => import("./pages/ValidationUser/RejectUser"));
const RouteProtector = lazy(() =>
    import("./components/routeProtector/RouteProtector")
);
const Schedule1 = lazy(() => import("./pages/Schedule/Schedule1"));
const SideBar = lazy(() => import("./pages/layout/SideBar"));
const SkillsState = lazy(() => import("./context/SkillsContext/SkillsState"));
const Team = lazy(() => import("./pages/Team/Team"));
const TeamSummary = lazy(() => import("./pages/TeamSummary/TeamSummary"));
const Track = lazy(() => import("./pages/Gps/Components/Track"));
const User = lazy(() => import("./pages/User/User"));
const UserProfile = lazy(() => import("./pages/UserProfile/UserProfile"));
const TableViewStatics = lazy(() =>
    import("./pages/TeamManage/components/TableViewStatics")
);
const TeamManage = lazy(() => import("./pages/TeamManage/TeamManage"));
const TeamManageAdd = lazy(() => import("./pages/TeamManage/TeamManageAdd"));
const TeamManageEdit = lazy(() => import("./pages/TeamManage/TeamManageEdit"));
const VideoAnalisys = lazy(() => import("./pages/VideoAnalisis/VideoAnalisys"));
const Analisys = lazy(() => import("./pages/VideoAnalisis/Steps/Analisys"));
const VideoAnalysisResume = lazy(() => import("./pages/VideoAnalisis/VideoAnalysisResume/VideoAnalysisResume"));
const VideoAnalysisEdit = lazy(() => import("./pages/VideoAnalisis/VideoAnalysisEdit/VideoAnalysisEdit"));
const VideoanalysisSummary = lazy(() => import("./pages/VideoAnalysisSummary/VideoanalysisSummary"));
const AddNewAnalisys = lazy(() =>
    import("./pages/VideoAnalisis/AddNewAnalisys")
);
const GoalkeeperMain = lazy(() =>
    import("./pages/GoalkeeperExercise/GoalkeeperMain")
);
const GoalkeeperNewCreate = lazy(() =>
    import("./pages/GoalkeeperExercise/GoalkeeperNewCreate")
);
const TrainingReport = lazy(() =>
    import("./pages/GoalkeeperExercise/TrainingReport")
);
const TrainingPlayerReport = lazy(() =>
    import("./pages/GoalkeeperExercise/TrainingPlayerReport")
);
const NewPlayerSummary = lazy(() =>
    import("./pages/newPlayerSummary/NewPlayerSummary")
);
const WellnessTest = lazy(() => import("./pages/Wellness/Wellness"));
const SalesPotential = lazy(() =>
    import("./pages/SalesPotential/SalesPotential")
);
const ShowOportunityMap = lazy(() =>
    import("./pages/PlayerSummary/ShowOportunityMap")
);
const SuperAdmin = lazy(() => import("./pages/SuperAdmin/SuperAdmin"));
const SuperAdminClub = lazy(() => import("./pages/SuperAdmin/SuperAdminClub"));
const SuperAdminBilling = lazy(() =>
    import("./pages/SuperAdmin/SuperAdminBilling")
);
const SuperAdminPartners = lazy(() =>
    import("./pages/SuperAdmin/SuperAdminPartners")
);
const SuperAdminInvestors = lazy(() =>
    import("./pages/SuperAdmin/SuperAdminInvestors")
);
const SuperAdminOpMap = lazy(() =>
    import("./pages/SuperAdmin/SuperAdminOpMap")
);
const IdealTeam = lazy(() => import("./pages/IdealTeam/IdealTeam"));
const NewPlayer = lazy(() => import("./pages/newPlayer/NewPlayer"));
const FreePlayers = lazy(() => import("./pages/FreePlayers/FreePlayers"));
const Scouting = lazy(() => import("./pages/Scouting/Scouting"));
const WinStrategy = lazy(() => import("./pages/WinStrategy/WinStrategy"));
const Contract = lazy(() => import("./pages/newPlayerSummary/tabs/Contract"));
const Tutors = lazy(() => import("./pages/newPlayerSummary/tabs/Tutors"));
const TransferTracker = lazy(() => import("./pages/TransferTracker/TransferTracker"));
const TranslationMain = lazy(() => import("./pages/TranslationManagement/TranslationMain"));
const MyPupils = lazy(() => import("./pages/MyPupils/MyPupils"));
const AdminDataSharing = lazy(() => import("./pages/Admin/AdminDataSharing/AdminDataSharing"))


//CONTEXT
const CategoryState = lazy(() =>
    import("./context/CategoryContext/CategoryState")
);
const ClubState = lazy(() => import("./context/ClubContext/ClubState"));
const DocumentationState = lazy(() =>
    import("./context/DocumentationContext/DocumentationState")
);
const EvaluationState = lazy(() =>
    import("./context/EvaluationContext/EvaluationState")
);
const PlayerState = lazy(() => import("./context/PlayerContext/PlayerState"));
const ScheduleState = lazy(() =>
    import("./context/ScheduleContext/ScheduleState")
);
const TeamState = lazy(() => import("./context/TeamContext/TeamState"));
const UserState = lazy(() => import("./context/UserContext/UserState"));

const VideoAnalisysState = lazy(() =>
    import("./context/VideoAnalisysContext/VideoAnalisysState")
);
const TournamentState = lazy(() =>
    import("./context/TournamentContext/TournamentState")
);
const StatsState = lazy(() => import("./context/StatsContext/StatsState"));
const WellnessState = lazy(() =>
    import("./context/WellnessContext/WellnessState")
);
const LeagueState = lazy(() => import("./context/LeagueContext/LeagueState"));
// eslint-disable-next-line
const translate = lazy(() => import("./lang/lang"));
const TrainingEvaluation = lazy(() =>
    import("./pages/GoalkeeperExercise/TrainingEvaluation")
);
const ExerciseState = lazy(() =>
    import("./context/ExerciseContext/ExerciseState")
);
const SuscriptionsFan = lazy(() =>
    import("./pages/SuscriptionsFan/SuscriptionsFan")
);
const SuscriptionsLayout = lazy(() =>
    import("./pages/layout/SuscriptionsLayout")
);
const CategorySelected = lazy(() =>
    import("./pages/Category/CategorySelected")
);
const TournamentSelected = lazy(() =>
    import("./pages/Tournaments/TournamentSelected")
);
const MatchSelected = lazy(() => import("./pages/Tournaments/MatchSelected"));
const ContractForm = lazy(() =>
    import("./pages/Admin/AdminUsers/components/ContractForm")
);
const GuardianForm = lazy(() =>
    import("./pages/Admin/AdminUsers/components/GuardianForm")
);
const Tournaments = lazy(() => import("./pages/Tournaments/Tournaments"));
const Agreement = lazy(() => import("./pages/Agreement/TutorAgreement"));
const ClubAgreement = lazy(() => import("./pages/ClubAgreement/ClubAgreement"));
const SuccessZone = lazy(() =>
    import("./pages/SuccessZonePlayers/SuccessZonePlayers")
);
const HopeZone = lazy(() => import("./pages/HopeZonePlayers/HopeZonePlayers"));
const PromiseZone = lazy(() =>
    import("./pages/PromiseZonePlayers/PromiseZonePlayers")
);
const ProfessionalPlayers = lazy(() =>
    import("./pages/ProfessionalPlayers/ProfessionalPlayers")
);
const ResidencePlayers = lazy(() =>
    import("./pages/ResidencePlayers/ResidencePlayers")
);
const EmoHealth = lazy(() => import("./pages/EmoHealth/EmoHealth"));
const TeamBuilder = lazy(() => import("./pages/TeamBuilder/TeamBuilder"));
const Biotype = lazy(() => import("./pages/Biotype/Biotype"));
const ExternalConnector = lazy(() => import("./pages/ExternalConnector/ExternalConnector"));
const Gesdep = lazy(() => import("./pages/Gesdep/Gesdep"));
const GameNewEdit = lazy(() => import("./pages/Tournaments/GameNewEdit"));
const PlayerTournaments = lazy(() => import("./pages/newPlayerSummary/tabs/Tournaments"));
const SuperAdminGamesUpdate = lazy(() => import("./pages/SuperAdmin/SuperAdminGamesUpdate"));
const Support = lazy(() => import("./pages/Support/Support"))
const MediaLibrary = lazy(() => import("./pages/MediaLibary/MediaLibrary"));
const Games = lazy(() => import("./pages/Games/Games"));

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: clientId,
                scope: "",
            });
        }
        gapi.load("client:auth2", start);
    });

    return (
        <div className="App">
            <Router>
                <Suspense
                    fallback={
                        <div style={{ backgroundColor: "#163647" }}>
                            <SpinnerNotLogged />
                        </div>
                    }
                >
                    <Route exact path="/" render={() => <Redirect to="/login" />} />
                    <Switch>
                        <Route
                            exact
                            path="/club-agreement"
                        >
                            <ClubAgreement/>
                        </Route>
                        <FanState>
                            <UserState>
                                <DocumentationState>
                                    <ClubState>
                                        <ScheduleState>
                                            <CategoryState>
                                                <TeamState>
                                                    <PlayerState>
                                                        <EvaluationState>
                                                            <VideoAnalisysState>
                                                                <TournamentState>
                                                                    <SkillsState>
                                                                        <StatsState>
                                                                            <WellnessState>
                                                                                <LeagueState>
                                                                                    <AuthLayoutRoute
                                                                                        exact path="/register"
                                                                                        component={Register}
                                                                                    />
                                                                                    <AuthLayoutRoute
                                                                                        exact path="/login"
                                                                                        component={NewLogin}
                                                                                    />
                                                                                    <AuthLayoutRoute
                                                                                        exact path="/new-trial-user"
                                                                                        component={NewTrialUserRegister}
                                                                                    />
                                                                                    <AuthLayoutRoute
                                                                                        exact path="/payment"
                                                                                        component={NewPayment}
                                                                                    />
                                                                                    <AuthLayoutRoute
                                                                                        exact path="/recover"
                                                                                        component={Recover}
                                                                                    />
                                                                                    <AuthLayoutRoute
                                                                                        exact path="/user-acepted"
                                                                                        component={AceptUser}
                                                                                    />
                                                                                    <AuthLayoutRoute
                                                                                        exact path="/user-rejected"
                                                                                        component={RejectUser}
                                                                                    />
                                                                                    <AuthLayoutRoute
                                                                                        exact path="/agreement"
                                                                                        component={Agreement}
                                                                                    />
                                                                                    <Route exact path="/newAgreement">
                                                                                        <NewAgreement
                                                                                        />
                                                                                    </Route>
                                                                                    {/* <AuthLayoutRoute
                                                                                        exact path="/newAgreement"
                                                                                        component={NewAgreement}
                                                                                    /> */}
                                                                                    <Route exact path="/suscriptionsfan">
                                                                                        <SuscriptionsLayout>
                                                                                            <SuscriptionsFan />
                                                                                        </SuscriptionsLayout>
                                                                                    </Route>

                                                                                    <RouteProtector>
                                                                                        <NavigationBarComponent />
                                                                                        <PathNavigator />
                                                                                        <Breadcrum />
                                                                                        <Route exact path="/">
                                                                                            <Dashboard />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/translation-management"
                                                                                        >
                                                                                            <TranslationMain />
                                                                                        </Route>
                                                                                        <Route exact path="/main-panel">
                                                                                            <Dashboard />
                                                                                        </Route>
                                                                                        <Route exact path="/player">
                                                                                            <NewPlayer />
                                                                                        </Route>
                                                                                        <Route exact path="/gps">
                                                                                            <Gps />
                                                                                        </Route>
                                                                                        <Route exact path="/gps/track/:id">
                                                                                            <Track />
                                                                                        </Route>
                                                                                        <Route exact path="/category">
                                                                                            <Category />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/category/:categoryId"
                                                                                        >
                                                                                            <CategorySelected />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/make-evaluation"
                                                                                        >
                                                                                            <MakeEvaluation />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/summary/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <PlayerSummary />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/summary/new/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <NewPlayerSummary />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/contract/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <Contract />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/tutors/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <Tutors />
                                                                                        </Route>
                                                                                        <Route exact path="/scouting">
                                                                                            <Scouting />
                                                                                        </Route>
                                                                                        <Route exact path="/win-strategy">
                                                                                            <WinStrategy />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/transfer-tracker"
                                                                                        >
                                                                                            <TransferTracker />
                                                                                        </Route>
                                                                                        <Route exact path="/team-builder">
                                                                                            <TeamBuilder />
                                                                                        </Route>
                                                                                        <Route exact path="/emo-health">
                                                                                            <EmoHealth />
                                                                                        </Route>
                                                                                        <Route exact path="/success-zone">
                                                                                            <SuccessZone />
                                                                                        </Route>
                                                                                        <Route exact path="/hope-zone">
                                                                                            <HopeZone />
                                                                                        </Route>
                                                                                        <Route exact path="/promise-zone">
                                                                                            <PromiseZone />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/professional-players"
                                                                                        >
                                                                                            <ProfessionalPlayers />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/residence-players"
                                                                                        >
                                                                                            <ResidencePlayers />
                                                                                        </Route>
                                                                                        <Route exact path="/biotype">
                                                                                            <Biotype />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/documentation/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <PlayerDocumentation />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/profile/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <PlayerProfile />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/valoration/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <MakeEvaluationPlayer />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/om/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <ShowOportunityMap />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/player/video_analysis/player_id/:id/user_id/:userId"
                                                                                        >
                                                                                            <VideoanalysisSummary />
                                                                                        </Route>
                                                                                        <Route exact path="/salesPotential">
                                                                                            <SalesPotential />
                                                                                        </Route>
                                                                                        <Route exact path="/tournament">
                                                                                            <Tournaments />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/tournament-selected/tournament/:tournamentId/:tournamentTeamId/category/:categoryId"
                                                                                        >
                                                                                            <TournamentSelected />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/tournament-selected/tournament/:tournamentId/:tournamentTeamId/category/:categoryId/match/:matchId"
                                                                                        >
                                                                                            <MatchSelected />
                                                                                        </Route>
                                                                                        <Route exact path="/superAdmin">
                                                                                            <SuperAdmin />
                                                                                        </Route>
                                                                                        <Route exact path="/superAdminClub">
                                                                                            <SuperAdminClub />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/superAdminBilling"
                                                                                        >
                                                                                            <SuperAdminBilling />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/superAdminPartners"
                                                                                        >
                                                                                            <SuperAdminPartners />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/superAdminInvestors"
                                                                                        >
                                                                                            <SuperAdminInvestors />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/superAdminOpMap"
                                                                                        >
                                                                                            <SuperAdminOpMap />
                                                                                        </Route>
                                                                                        <Route exact path="/admin/users">
                                                                                            <AdminLayout
                                                                                                titlePage="users management"
                                                                                                breadcrumActive="users"
                                                                                            >
                                                                                                <AdminUsers />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/admin/users/add-contract/player_id/:id"
                                                                                        >
                                                                                            <AdminLayout breadcrumActive="users">
                                                                                                <ContractForm />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/admin/users/add-guardian/user_id/:id"
                                                                                        >
                                                                                            <AdminLayout breadcrumActive="users">
                                                                                                <GuardianForm />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route exact path="/admin/clubs">
                                                                                            <AdminLayout
                                                                                                titlePage="clubs management"
                                                                                                breadcrumActive="clubs"
                                                                                            >
                                                                                                <AdminClub />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/admin/categories"
                                                                                        >
                                                                                            <AdminLayout
                                                                                                titlePage="categories management"
                                                                                                breadcrumActive="categories"
                                                                                            >
                                                                                                <AdminCategories />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/admin/evaluations"
                                                                                        >
                                                                                            <AdminLayout
                                                                                                titlePage="evaluations management"
                                                                                                breadcrumActive="evaluations"
                                                                                            >
                                                                                                <AdminEvaluations />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route exact path="/admin/teams">
                                                                                            <AdminLayout
                                                                                                titlePage="teams management"
                                                                                                breadcrumActive="teams"
                                                                                            >
                                                                                                <AdminTeams />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route exact path="/team">
                                                                                            <Team />
                                                                                        </Route>
                                                                                        {/* <Route exact path="/team/summary/:id">
                                          <SideBar
                                            breadcrumVisible={false}
                                            breadcrumActive="team"
                                            className="background-dashboard"
                                            title="team summary"
                                            notUseparams="true"
                                          >
                                            <TeamSummary />
                                          </SideBar>
                                        </Route> */}
                                                                                        {/* <Route exact path="/team/manage/:id">
                                          <SideBar
                                            breadcrumVisible={false}
                                            breadcrumActive="team manage"
                                            className="background-dashboard"
                                            title="manage team"
                                            notUseparams="true"
                                          >
                                            <TeamManage />
                                          </SideBar>
                                        </Route> */}
                                                                                        <Route exact path="/idealTeam">
                                                                                            <IdealTeam />
                                                                                        </Route>
                                                                                        {/* <Route exact path="/admin/clubs">
                                          <AdminLayout
                                            titlePage="clubs management"
                                            breadcrumActive="clubs"
                                          >
                                            <AdminClub />
                                          </AdminLayout>
                                        </Route> */}
                                                                                        {/* <Route exact path="/admin/categories">
                                          <AdminLayout
                                            titlePage="categories management"
                                            breadcrumActive="categories"
                                          >
                                            <AdminCategories />
                                          </AdminLayout>
                                        </Route> */}
                                                                                        <Route
                                                                                            exact
                                                                                            path="/admin/evaluations"
                                                                                        >
                                                                                            <AdminLayout
                                                                                                titlePage="evaluations management"
                                                                                                breadcrumActive="evaluations"
                                                                                            >
                                                                                                <AdminEvaluations />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/team/summary/:id"
                                                                                        >
                                                                                            <TeamSummary />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/team/manage/:id"
                                                                                        >
                                                                                            <TeamManage />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/team/manage/:id/tableviewstatics/:gameId"
                                                                                        >
                                                                                            <TableViewStatics />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/team/manage/add/:id"
                                                                                        >
                                                                                            <TeamManageAdd />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/team/manage/:id/edit/:gameId"
                                                                                        >
                                                                                            <TeamManageEdit />
                                                                                        </Route>
                                                                                        <Route exact path="/user">
                                                                                            <User />
                                                                                        </Route>
                                                                                        <Route exact path="/club">
                                                                                            <Club />
                                                                                        </Route>
                                                                                        <Route exact path="/team/create/1">
                                                                                            <CreateTeam />
                                                                                        </Route>
                                                                                        <Route exact path="/team/create/2">
                                                                                            <CreatePlayerList />
                                                                                        </Route>
                                                                                        <Route exact path="/documentation">
                                                                                            <Documentation />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/admin/users/profile/:id"
                                                                                        >
                                                                                            <UserProfile />
                                                                                        </Route>
                                                                                        <Route exact path="/create/user">
                                                                                            <AdminLayout
                                                                                                breadcrumVisible={false}
                                                                                                breadcrumActive="Create User"
                                                                                                className="background-dashboard"
                                                                                                title="create user"
                                                                                            >
                                                                                                <CreateUser />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route exact path="/schedule">
                                                                                            <Schedule1 />
                                                                                        </Route>
                                                                                        <Route exact path="/create/event">
                                                                                            <CreateEvent />
                                                                                        </Route>
                                                                                        <Route exact path="/video-analisys">
                                                                                            <VideoAnalisys />
                                                                                        </Route>

                                                                                        <Route
                                                                                            exact
                                                                                            path="/video-analysis/resume/:id"
                                                                                        >
                                                                                            <VideoAnalysisResume />
                                                                                        </Route>

                                                                                        <Route
                                                                                            exact
                                                                                            path="/video-analysis/edit/:id"
                                                                                        >
                                                                                            <VideoAnalysisEdit />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/videoanalisys-build"
                                                                                        >
                                                                                            <AddNewAnalisys />
                                                                                        </Route>
                                                                                        <Route exact path="/admin/create">
                                                                                            <AdminLayout
                                                                                                titlePage="admin create"
                                                                                                breadcrumActive="create"
                                                                                            >
                                                                                                <AdminCreate />
                                                                                            </AdminLayout>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/create-video-analysis"
                                                                                        >
                                                                                            <Analisys />
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/create-video-analysis/:id"
                                                                                        >
                                                                                            <Analisys />
                                                                                        </Route>
                                                                                        <Route exact path="/training">
                                                                                            <ExerciseState>
                                                                                                <GoalkeeperMain />
                                                                                            </ExerciseState>
                                                                                        </Route>
                                                                                        <Route exact path="/training-new">
                                                                                            <ExerciseState>
                                                                                                <GoalkeeperNewCreate />
                                                                                            </ExerciseState>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/training-evaluation/:id"
                                                                                        >
                                                                                            <ExerciseState>
                                                                                                <TrainingEvaluation />
                                                                                            </ExerciseState>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/training-report/:id"
                                                                                        >
                                                                                            <ExerciseState>
                                                                                                <TrainingReport />
                                                                                            </ExerciseState>
                                                                                        </Route>
                                                                                        <Route
                                                                                            exact
                                                                                            path="/training-player-report/:id"
                                                                                        >
                                                                                            <ExerciseState>
                                                                                                <TrainingPlayerReport />
                                                                                            </ExerciseState>
                                                                                        </Route>
                                                                                        <Route exact path="/wellness-test">
                                                                                            <WellnessTest />
                                                                                        </Route>
                                                                                        <Route exact path="/free-players">
                                                                                            <FreePlayers />
                                                                                        </Route>
                                                                                        <Route exact path="/external_connector">
                                                                                            <ExternalConnector />
                                                                                        </Route>
                                                                                        <Route exact path="/gesdep">
                                                                                            <Gesdep />
                                                                                        </Route>
                                                                                        <Route exact path="/gameNewEdit">
                                                                                            <GameNewEdit />
                                                                                        </Route>
                                                                                        <Route exact path="/player/tournaments/player_id/:id/user_id/:userId">
                                                                                            <PlayerTournaments />
                                                                                        </Route>
                                                                                        <Route exact path="/superAdminGamesUpdate">
                                                                                            <SuperAdminGamesUpdate />
                                                                                        </Route>
                                                                                        <Route exact path="/myPupils">
                                                                                            <MyPupils />
                                                                                        </Route>
                                                                                        <Route exact path="/adminDataSharing">
                                                                                            <AdminDataSharing />
                                                                                        </Route>
                                                                                        <Route exact path="/support">
                                                                                            <Support />
                                                                                        </Route>
                                                                                        <Route exact path="/mediaLibrary">
                                                                                            <MediaLibrary />
                                                                                        </Route>
                                                                                        <Route  exact path="/games">
                                                                                            <Games />
                                                                                        </Route>
                                                                                    </RouteProtector>
                                                                                </LeagueState>
                                                                            </WellnessState>
                                                                        </StatsState>
                                                                    </SkillsState>
                                                                </TournamentState>
                                                            </VideoAnalisysState>
                                                        </EvaluationState>
                                                    </PlayerState>
                                                </TeamState>
                                            </CategoryState>
                                        </ScheduleState>
                                    </ClubState>
                                </DocumentationState>
                            </UserState>
                        </FanState>
                    </Switch>
                </Suspense>
            </Router>
        </div>
    );
}
export default App;