// import en from "./en.json";
// import es from "./es.json";
// import it from "./it.json";
// import fr from "./fr.json";
// import jp from "./jp.json";
// import pt from "./pt.json";
// import de from "./de.json";

function translate(text) {
  const dictionary = JSON.parse(localStorage.getItem("dictionary"));
  if (!dictionary) {
    return text;
  }

  return dictionary[text] ? dictionary[text] : text;
}

export default translate;

//lang = lang ? lang : JSON.parse(localStorage.getItem("user")).language;

//useEffect haciendo el get de idioma cada vez que cambia lang,
//return (lang[text] ? lang(text) : text)

// switch (lang) {
//   case "es":
//     return es[text] ? es[text] : text;
//   case "it":
//     return it[text] ? it[text] : text;
//   case "fr":
//     return fr[text] ? fr[text] : text;
//   case "jp":
//     return jp[text] ? jp[text] : text;
//   case "pt":
//     return pt[text] ? pt[text] : text;
//   case "de":
//     return de[text] ? de[text] : text;
//   default:
//     return en[text] ? en[text] : text;
// }
