import "./Styles/Spinner.css";

const Spinner = () => {
  return (
    <div className="container">
      <div className="paper">
        <div style={{ textAlign: "center" }}>
          <span className="loader"></span>
          <p className="text">GLOOUDS</p>
          {/* <CircularProgress /> */}
        </div>
      </div>
    </div>
  );
};

export default Spinner;
